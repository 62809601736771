.image {
  display: block;
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  position: relative;
  text-align: center;
  transition: opacity 1s ease-in-out;
  overflow: auto;
  padding: 10px;
  padding-bottom: 50px;
}

.title {
  padding: 20px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: clamp(20px, 5.5vw, 20vw);
  background-color: white;
  color: black;
  border-radius: clamp(10px, 1vw, 1vw);
  flex: 1;
  display: inline-block;
  line-height: 1;
}

.subtitle {
  //@extend .title;
  font-size: clamp(12px, 3vw, 12vw);
  text-transform: uppercase;
  line-height: 1;
}

.counterContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
}

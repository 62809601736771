.container {
  position: relative;
  margin: clamp(10px, 1vw, 1vw);
  min-width: 150px;
  width: clamp(150px, 12vw, 12vw);
  border-radius: clamp(10px, 1vw, 1vw);
  box-shadow: 1vw 1vw 3vw 0.1vw rgba(0, 0, 0, 0.4);
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;
  text-align: center;

  .label {
    font-size: clamp(20px, 2vw, 10vw);
    padding: clamp(15px, 1vw, 1vw);
    background-color: red;
    color: white;
    text-transform: uppercase;
  }

  .value {
    background-color: white;
    font-size: clamp(70px, 5.5vw, 20vw);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.small {
    .label {
      font-size: 12px;
    }

    .value {
      font-size: 16px;
    }
  }
}

.smallContainer {
  margin: 10px;

  .label {
    font-size: clamp(10px, 3.5vw, 10vw);
  }

  .value {
    font-size: clamp(20px, 5.5vw, 20vw);
  }
}



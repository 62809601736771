$btn-size: 120px;
$btn-font-size: $btn-size * 0.25;
$btn__icon-size: $btn-size * 0.6;

.btn {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 1;
  transition: opacity 0.5s ease-in-out;
  background: red;
  color: white;
  font-size: $btn-font-size;
  font-weight: bold;
  text-transform: uppercase;

  border: 3px solid black;
  border-radius: 50%;
  width: $btn-size;
  height: $btn-size;
  cursor: pointer;
  box-shadow: 1vw 1vw 3vw 0.1vw rgba(0, 0, 0, 0.4);

  > svg {
    width: $btn__icon-size;
    height: $btn__icon-size;
  }
}

:global {
  .panic {
    animation-name: bounce;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 3;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
  }
}

@keyframes:global(bounce) {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, .9) translateY(0);
  }
  30% {
    transform: scale(.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes:global(shake) {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}


